$platform-name: 'National Kidney Federation';
$assets-path: "../assets/" ;

/// Start Colour ///

/* Brand Colours */
$brand-primary: #1D3275;
$brand-secondary: #F08000;
$brand-tertiary: #E17801;
$brand-quarternary: #3658CA;

/* Hover Colours */
$hover-primary: #3558CA;
$hover-secondary: #E17801;
$hover-ghost: #1B3476;

/* Background Palette */
$bg-colour: #BBD3FB;
$bg-dark: #1C3375;
$bg-light: #E5EBFB;
$bg-white: #FFFFFF;
$bg-hue-white-1: #F3F5F8;
$bg-hue-white-2: #F8F9FB;

/* Font Palette */
$headings-colour: #1C3375;
$link-colour: #E27900;
$body-colour: #6E6E6E;

/* Other Colours */
$white: #FFFFFF;
$black: #000000;
$grey: #979797;
$focus: #1179C3;

/// End Colour ///

/// Start Fonts ///
@font-face {
  font-family: "Roboto";
  src: url($assets-path + "Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url($assets-path + "Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url($assets-path + "Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: bolder;
  font-display: swap;
}
/// End Fonts ///

/// Start Typography ///
$font-family-base: "Roboto", sans-serif;
$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$font-weight-bold: 700;
$font-weight-black: 900;
$font-weight-normal: 400;
$line-height-base: 1.5;
$font-weight-base: $font-weight-normal;
$headings-font-weight: $font-weight-black;

$font-size-h1: 47.78px;
$font-size-h2: 39.81px;
$font-size-h3: 33.18px;
$font-size-h4: 27.65px;
$font-size-h5: 23.04px;
$font-size-h6: 19.20px;

$font-mobile-h1: 38.22px;
$font-mobile-h2: 31.85px;
$font-mobile-h3: 26.54px;
$font-mobile-h4: 22.12px;
$font-mobile-h5: 18.43px;
/// End Typography ///

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-max-width: 1200px;
$container-grid-size: 85%;
$content-max-width: 800px;
$site-bleed: 0px;

// Logo
$logo: "logo.svg";
$logo-width: 154px;
$logo-height: 115px;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $white;
$btn-border-radius: 500px;
$btn-border-width: 1px;
$btn-padding-x: 28px;
$btn-padding-y: 13px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: 18px;
$btn-hover-style: lighten; // darken or lighten the button on hover

// Menu admin
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-background-colour: transparent;
$menu-admin-link-colour: $brand-primary;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

// Navigations
$nav-breakpoint: 1299px;
$nav-background-colour: transparent;

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: 100%;
$nav-normal-align-items: right; // left, center, right
$nav-normal-mobile-background-colour: $bg-white;
$nav-normal-mobile-top-level-item-colour: $headings-colour;
$nav-normal-mobile-submenu-background-colour: $bg-light;
$nav-normal-mobile-submenu-item-colour: $headings-colour;

// Top level items
$nav-top-level-item-padding: 0;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-width: max-content;
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-border-radius: 8px;
$nav-submenu-box-shadow: 2px 2px 10px 0px rgba($white, 0.05);
$nav-submenu-item-padding: 18px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-hover-colour: $brand-secondary;
$nav-submenu-item-hover-background-colour: transparent;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 0;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $brand-primary;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $body-colour;
$carousel-summary-font-size: $font-size-large;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $brand-tertiary;
$carousel-read-more-border-colour: $brand-tertiary;
$carousel-read-more-colour: $white;
$carousel-read-more-font-size: $btn-font-size;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 60px;
$carousel-controls-button-gap: 15px;
$carousel-controls-button-background-colour: $brand-primary;
$carousel-controls-button-border-radius: 50%;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: left; // split, left or right
$carousel-controls-icon-colour: $white;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $carousel-heading-colour;
$carousel-mobile-summary-colour: $carousel-summary-colour;

// Home intro
$home-intro-content-max-width: $container-max-width;
$home-intro-background-colour: $bg-hue-white-2;
$home-intro-padding-y: 40px;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-font-family: $font-family-base;
$home-intro-font-weight: $font-weight-base;
$home-intro-font-size: $font-size-h4;
$home-intro-border-radius: 0;
$home-intro-line-height: 1.389;
$home-intro-colour: $headings-colour;
$home-intro-mobile-padding-y: $home-intro-padding-y * .7;
$home-intro-mobile-font-size: $home-intro-font-size * .85;

// Home features
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-border-radius: 0;
$quick-giving-donation-amount-figure-font-size: 27.65px;
$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-figure-colour: $white;
$quick-giving-donation-amount-border-width: 0;
$quick-giving-donation-amount-border-colour: transparent;
$quick-giving-donation-amount-selected-background-colour: transparent;
$quick-giving-donation-amount-selected-figure-colour: $white;

// Home quick giving panel
$home-quick-giving-padding-y: 0;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: $white;
$home-quick-giving-donation-amount-border-width: 0; // Only comes into play with transparent background
$home-quick-giving-donation-amount-border-colour: transparent;
$home-quick-giving-donation-amount-selected-background-colour: transparent;
$home-quick-giving-donation-amount-selected-figure-colour: $white;
$home-quick-giving-donate-btn-background-colour: $brand-tertiary;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: $brand-tertiary;
$home-quick-giving-donate-btn-font-size: $font-size-large;
$home-quick-giving-colour: $white; // This should cover any headings and text you have in the snippet
$home-quick-giving-heading-colour: $white;

// Impact stats
$impact-stats-max-width: $container-max-width;
$impact-stats-padding-y: 0;
$impact-stats-background-colour: $brand-primary;
$impact-stats-border-radius: 15px;
$impact-stats-colour: $white;
$impact-stats-text-align: left; // left, center, right
$impact-stats-content-max-width: 100%;
$impact-stats-breakpoint: 767px; // When do you want to break the overall layout from row to column?

// Impact heading
$impact-stats-heading-enabled: false; //true or false

// Impact individual stat
$impact-stat-min-width: auto; // Use this to control how many you get in a row
$impact-stat-direction: row; // column, row
$impact-stat-margin: 0;

// Impact figure
$impact-stats-figure-colour: $white;
$impact-stats-figure-font-size: 64px;

// Impact summary
$impact-stats-summary-colour: $white;
$impact-stats-summary-font-weight: $font-weight-bold;

// Card
$card-gap-width: 30px;
$card-border-radius: 0;
$card-border: 0;
$card-image-background-colour: $bg-hue-white-2;
$card-image-border-radius: 15px;
$card-details-padding: 20px 0 0;
$card-details-background-colour: transparent;
$card-heading-colour: $brand-primary;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: 0;

// Card hover state
$card-hover-border-colour: transparent;
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: transparent;

// Slick
$slick-enabled: true;

// Home feeds
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Feed item
$feed-item-read-more-enabled: true;

// Newsletter
$newsletter-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-contents-max-width: 100%;

// Footer
$footer-colour: $white;
$footer-link-colour: $white;
$footer-link-decoration: false;
$footer-link-hover-colour: $brand-tertiary;
$footer-padding-y: 0;
$footer-background-colour: transparent;

// Social
$social-icons-background-colour: $bg-white; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: $white; // A colour, or 'brand'
$social-icons-font-size: $font-size-large;
$social-icons-width: 45px;
$social-icons-height: 45px;
$social-icons-gap: 20px;

// Shop
$basket-link-only-on-shop-pages: true;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: 0;
$header-text-margin-bottom: 0;

// Page title
$page-title-colour: $white;
$page-title-margin-bottom: 0;

// Breadcrumb
$breadcrumb-margin-top: 0;
$breadcrumb-margin-bottom: 5px;
$breadcrumb-max-width: 100%;
$breadcrumb-text-align: left; // left, center or right

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-adjacent-gap: 30px; // distance between sidebar and post content
$sidebar-border-radius: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-h5;
$associated-item-heading-colour: $headings-colour;
$associated-item-summary-enabled: false;
$associated-item-border: false;

// Share this page
$share-label-font-size: $font-size-large;
$share-label-font-colour: $brand-primary;

// Donate button
$donate-btn-colour: $white;

// FAQs
$faq-item-header-background-colour: transparent;
$faq-item-header-padding: 30px 40px;
$faq-item-header-border-radius: 0;
$faq-item-btn-background-colour: $brand-primary;
$faq-item-btn-chevron-colour: $white;
$faq-item-hover-btn-background-colour: $brand-primary;
$faq-item-hover-btn-chevron-colour: $white;

// Blockquote
$blockquote-background-colour: $brand-tertiary;
$blockquote-border-radius: 50px 10px;
$blockquote-margin-y: 0;
$blockquote-padding: 30px 40px;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-h5; // Em unit will cascade to children
$blockquote-font-weight: $font-weight-black;
$blockquote-line-height: 1.302;
$blockquote-colour: $white;

// Departments list
$department-list-border-radius: 15px;